<template>
  <v-container class="py-6">
    <v-row>
      <v-col cols="12">
        <div class="box-wrapper">
          <div class="box-overlay" :class="{ open: isSidebar }" @click="isSidebar = !isSidebar"></div>
          <div class="box-sidebar pb-3 shadow-sm" :class="{ open: isSidebar }">
            <DashbordSidebarTwo />
          </div>

          <!-- <v-card class="ml-2" elevation="0">
            <v-row>
              <v-card-title>
                <v-breadcrumbs class="text-uppercase font-weight-bolder" :items="itemsLink" />
              </v-card-title>
            </v-row>
          </v-card> -->

          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-card class="ma-3" elevation="0" v-for="(notification, index) in notifications" :key="index">
                <v-card-title class="headline text-uppercase">{{
                  notification.title
                }}</v-card-title>
                <v-card-subtitle>{{ notification.date }}</v-card-subtitle>
                <v-card-text>{{ notification.message }}</v-card-text>
                <!-- <v-card-actions>
                  <v-btn color="primary" @click="dismissNotification(index)">Dismiss</v-btn>
                </v-card-actions> -->
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { BASE_URL } from "@/configs/api";
import axios from "axios";

import DashbordSidebarTwo from "@/components/DashboardSidebarTwo.vue";
export default {
  components: {
    DashbordSidebarTwo,
  },
  data() {
    return {
      notifications: [],
      dialogVerProforma: false,
      produto: {},
      dialogAvaliarProduto: false,
      dialogFaturaDetalhes: false,
      item: {
        pagamento_vendas_online_items: [],
      },
      fatura: {
        pagamento_vendas_online_items: [],
      },
      pagamento_vendas_online_items: [],
      itemsLink: [
        {
          text: "Início",
          disabled: false,
          href: "/portal/pages/cliente/dashboard",
        },
        {
          text: "NOTIFICAÇÕES",
          disabled: true,
          href: "/",
        },
      ],
      pagamento: {},
      pagamentos: {
        pagamento_vendas_online_items: [],
      },
      dialogProdutosFatura: false,
      talPedidos: 0,
      query: {},
      pedidos: [
        {
          id: 1,
          faturaReference: "12FGH3",
          created_at: "06-04-2023",
          estado_factura: {
            id: 1,
            designacao: "ENTREGUE",
          },
          valor_a_pagar: "955",
        },
        {
          id: 2,
          faturaReference: "21FYH3",
          created_at: "13-04-2023",
          estado_factura: {
            id: 2,
            designacao: "A CAMINHO",
          },
          valor_a_pagar: "55459",
        },
      ],
      headers: [
        {
          text: "Código",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "Produto",
          value: "pagamento_vendas_online_items.nomeProduto",
        },
        {
          text: "Qty",
          value: "pagamento_vendas_online_items[0].quantidade",
        },
        {
          text: "Valor Pago",
          value: "totalPagamento",
        },
        {
          text: "Data",
          value: "dataPagamentoBanco",
        },
        {
          text: "Acções",
          value: "actions",
          sortable: false,
        },
      ],
      overlay: false,
      factura: {},
      factura_detalhes: {},
      pagamentos_facturas: [],
      facturasItems: [],
      search: "",
      loading: false,
      sortBy: "created_at",
      sortDesc: true,
      overlay: false,
      isSidebar: false,
      page: 1,
      itemsPerPage: 5,
      items: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 14, 15, 16, 17, 18, 19, 20,
      ],
      range: [0, 100, 500, 1000],
      itemsTwo: ["Low to High", "High to Low", "Date"],
    };
  },
  computed: {},
  created() {
    window.addEventListener("scroll", this.handleScroll);
    // this.getClientePagamentos();
    // this.getCartoes();
  },
  mounted() {
    this.checkUserIsAuth();
    // Simulando a criação de 5 notificações estáticas
    for (let i = 1; i <= 5; i++) {
      this.notifications.push({
        title: `Notificação ${i}`,
        date: new Date().toLocaleDateString(),
        message: `Esta é a notificação número ${i}`,
      });
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    checkUserIsAuth() {
      if (sessionStorage.getItem("TOKEN") == null) {
        this.$router.push("/");
      }
    },
    dismissNotification(index) {
      this.notifications.splice(index, 1);
    },
    printComprovativo(comprovativo) {
      window.open(comprovativo);
    },
    setAvaliarProduto() {
      let produto = this.produto;
      // alert(JSON.stringify(produto))
      console.log(produto);
    },
    async printFatura(fatura) {
      // /api/empresa/imprimirExtratoCartaoCliente/34
      let urlJasper = `${BASE_URL}/pagamentosVendaOnline/imprimir/${fatura}`;
      var token = sessionStorage.getItem("TOKEN");
      try {
        let response = await axios.get(urlJasper, {
          responseType: "arraybuffer",
          headers: {
            Authorization: `Bearer ${token}`, // Exemplo de cabeçalho de autorização
          },
        });
        if (response.status === 200) {
          var file = new Blob([response.data], {
            type: "application/pdf",
          });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.dialogVerProforma = false;
        }
      } catch (error) {
        console.log("Erro ao carregar pdf");
        this.dialogVerProforma = false;
      }
    },

    showDialogFaturaDetalhes(item) {
      this.fatura = item;
      this.pagamento_vendas_online_items = item.pagamento_vendas_online_items;
      this.dialogFaturaDetalhes = true;
    },
    showDialogProdutosFatura(item) {
      this.fatura = item;
      this.pagamento_vendas_online_items = item.pagamento_vendas_online_items;
      this.dialogProdutosFatura = true;
    },
    formatePrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    getClientePagamentos() {
      this.overlay = true;
      var TOKEN_SESSAO = sessionStorage.getItem("TOKEN");
      const url = `${BASE_URL}/listarTodosPagamentosVendaOnlineUserAutenticado`;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + TOKEN_SESSAO,
          },
        })
        .then((response) => {
          this.pagamentos = response.data.data;
          // this.pagamento_vendas_online_items =
          //   this.pagamento.pagamento_vendas_online_items;
          // alert(JSON.stringify(this.pagamentos));
          // console.log(this.pagamentos);
          // setTimeout(() => {
          //   this.overlay = false;
          // }, 1000);
        });
    },

    showPedidoDetalhes(item) {
      this.pagamento = item;
      this.dialogProdutosFatura = true;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>
